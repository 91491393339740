// extracted by mini-css-extract-plugin
export var containerSkeleton = "index-module--containerSkeleton--X8B7d";
export var shineForcontainerSkeleton = "index-module--shineForcontainerSkeleton--c1JtM";
export var container = "index-module--container--g3jFK";
export var coaches = "index-module--coaches--5eokX";
export var profile = "index-module--profile--CStTi";
export var coaches_names = "index-module--coaches_names--8iMhf";
export var card_body = "index-module--card_body--bJ24h";
export var card_left = "index-module--card_left--qRL+E";
export var tour_date = "index-module--tour_date--jEM8j";
export var tour_starting_date = "index-module--tour_starting_date--1x+U6";
export var session_day = "index-module--session_day--CTsGv";
export var session_month = "index-module--session_month--Snbsy";
export var tour_ending_date = "index-module--tour_ending_date--d-0X8";
export var airplane = "index-module--airplane--VVeBr";
export var card_info = "index-module--card_info--fdUYW";
export var card_title = "index-module--card_title--KKwZg";
export var card_day_text = "index-module--card_day_text--cFLjr";
export var card_right = "index-module--card_right--m6fhu";
export var session_more_info = "index-module--session_more_info--E73bM";
export var card_price = "index-module--card_price--IRivT";
export var session_status = "index-module--session_status--UVAg+";
export var status_scheduled = "index-module--status_scheduled--lYSVz";
export var status_almostConfirmed = "index-module--status_almostConfirmed--RGw3X";
export var status_confirmed = "index-module--status_confirmed--MAGoW";
export var status_almostFull = "index-module--status_almostFull--n2NAM";
export var status_soldOut = "index-module--status_soldOut---J9Uf";
export var cta_book = "index-module--cta_book--dSxZu";
export var cta_price = "index-module--cta_price--hJz6f";
export var showMore = "index-module--showMore--fxFaZ";
export var directBooking = "index-module--directBooking--8WHBh";