import classnames from "classnames"
import React from "react"
import * as styles from "./index.module.scss"
import Typography from "/src/components/ui/base/typography"
import { useTranslation } from "react-i18next"
import Button from "/src/components/ui/base/Button"
import { GatsbyImage } from "gatsby-plugin-image"
import moment from "moment"
import "moment/locale/it"
import {
  getTourCurrency,
  getTourDays,
  getTourLink,
} from "../../../../util/tour"
import { Link } from "gatsby"

const SessionCard = ({ session, tour, directBooking, skeleton }) => {
  const { t, i18n: l } = useTranslation()

  const getCustomerlyMessage = () => {
    switch (session.status) {
      case "scheduled":
        return `Ciao WeShoot,\nmi potete dare info sul viaggio ${
          tour.title
        }?\n\nMi interessa la partenza del ${moment(session.start)
          .locale("it_IT")
          .format("D MMMM YYYY")}.\n\nCi sono ancora posti?`
      case "waitingList":
        return `Ciao WeShoot,\nmi voglio mettere in lista d'attesa per il viaggio ${
          tour.title
        }?\n\nMi interessa la partenza del ${moment(session.start)
          .locale("it_IT")
          .format("D MMMM YYYY")}.`
      case "almostConfirmed":
        return `Ciao WeShoot,\nmi potete dare info sul viaggio ${
          tour.title
        }?\n\nMi interessa la partenza del ${moment(session.start)
          .locale("it_IT")
          .format("D MMMM YYYY")}.\n\nCi sono ancora posti?`
      case "confirmed":
        return `Ciao WeShoot,\nmi potete dare info sul viaggio ${
          tour.title
        }?\n\nMi interessa la partenza del ${moment(session.start)
          .locale("it_IT")
          .format("D MMMM YYYY")}.\n\nCi sono ancora posti?`
      case "soldOut":
        return `Ciao WeShoot,\nmi potete dare info sul viaggio ${
          tour.title
        }?\n\nMi interessa la partenza del ${moment(session.start)
          .locale("it_IT")
          .format("D MMMM YYYY")} che é in Sold Out.\n\nAvete altre date?`
      case "almostFull":
        return `Ciao WeShoot,\nmi potete dare info sul viaggio ${
          tour.title
        }?\n\nMi interessa la partenza del ${moment(session.start)
          .locale("it_IT")
          .format("D MMMM YYYY")}.\n\nCi sono ancora posti?`
      default:
        return `Ciao WeShoot,\nmi potete dare info sul viaggio ${
          tour.title
        }?\n\nMi interessa la partenza del ${moment(session.start)
          .locale("it_IT")
          .format("D MMMM YYYY")}.\n\nCi sono ancora posti?`
    }
  }

  const openCustomerly = () => {
    // const tourLink = directBooking
    //   ? `${process.env.APP_URL}/booking/${session.sessionId}/start`
    //   : getTourLink(tour, l)

    const message = getCustomerlyMessage()
    const encodedMessage = encodeURIComponent(message)

    window.open(`https://wa.me/393495269093?text=${encodedMessage}`)

    if (typeof window !== "undefined") {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "request_info",
      })
    }

    // window.customerly.sendNewMessage(getCustomerlyMessage())
    // window.customerly.attribute("checkout_link", tourLink)
    // window.customerly.update()
  }

  if (skeleton === true) {
    return <div className={classnames([styles.containerSkeleton])} />
  } else {
    const tourLink = directBooking
      ? `${process.env.APP_URL}/booking/${session.sessionId}/start`
      : getTourLink(tour, l)
    return (
      <div
        className={classnames([
          styles.container,
          directBooking === true ? styles["directBooking"] : styles["showMore"],
        ])}
        key={session.id}
      >
        <div className={classnames([styles.card_body])}>
          <div className={classnames([styles.card_left])}>
            <div className={classnames([styles.tour_date])}>
              <div className={classnames([styles.tour_starting_date])}>
                <Typography
                  variant="heading3"
                  className={classnames([styles.session_day])}
                >
                  {moment(session.start).format("D")}
                </Typography>
                <Typography
                  variant="heading6"
                  className={classnames([styles.session_month])}
                >
                  {moment(session.start).locale("it_IT").format("MMM")}
                </Typography>
              </div>
              <span className={classnames([styles.airplane])}>→</span>
              <div className={classnames([styles.tour_ending_date])}>
                <Typography
                  variant="heading3"
                  className={classnames([styles.session_day])}
                >
                  {moment(session.end).format("D")}
                </Typography>
                <Typography
                  variant="heading6"
                  className={classnames([styles.session_month])}
                >
                  {moment(session.end).format("MMM")}
                </Typography>
              </div>
            </div>

            <div className={classnames([styles.card_info])}>
              <Typography
                variant="heading3"
                className={classnames([styles.card_title])}
              >
                {directBooking ? (
                  <div>{tour.title}</div>
                ) : (
                  <Link to={tourLink} target="_self">
                    {tour.title}
                  </Link>
                )}
              </Typography>
              <div className={classnames([styles.card_day_text])}>
                {getTourDays(session.end, session.start)}{" "}
                {t("ui.components.base.sessionCard.days")}
              </div>

              <div
                className={"d-flex align-items-center justify-content-between"}
              >
                <div className={classnames([styles.coaches])}>
                  {session.users?.map(u => {
                    return (
                      <div className={classnames([styles.profile])} key={u.id}>
                        {u.profilePicture.localFile ? (
                          <GatsbyImage
                            image={
                              u.profilePicture.localFile.childImageSharp
                                .gatsbyImageData
                            }
                            alt={u.firstName}
                          />
                        ) : (
                          <img
                            width={36}
                            height={36}
                            src={u.profilePicture.formats.thumbnail.url}
                            alt={u.firstName}
                          />
                        )}
                      </div>
                    )
                  })}

                  <div className={classnames([styles.coaches_names])}>
                    {session.users?.map((u, index) => {
                      return (
                        <span key={u.id}>
                          {index === session.users.length - 1
                            ? u.firstName + " "
                            : u.firstName + " &"}
                        </span>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classnames([styles.card_right])}>
            <div className={classnames([styles.session_more_info])}>
              <div
                className={classnames([styles.session_status])}
                onClick={() => {
                  openCustomerly()
                }}
              >
                {session.status === "scheduled" && (
                  <span
                    className={classnames([
                      styles.session_status,
                      styles.status_scheduled,
                    ])}
                  >
                    <i className={"icon-calendar-check-o"} />{" "}
                    {t("ui.components.base.sessionCard.scheduled")}
                  </span>
                )}
                {session.status === "almostConfirmed" && (
                  <span
                    className={classnames([
                      styles.session_status,
                      styles.status_almostConfirmed,
                    ])}
                  >
                    <i className={"icon-users"} />
                    {t("ui.components.base.sessionCard.almostConfirmed")}
                  </span>
                )}
                {session.status === "confirmed" && (
                  <span
                    className={classnames([
                      styles.session_status,
                      styles.status_confirmed,
                    ])}
                  >
                    <i className={"icon-check"} />
                    {t("ui.components.base.sessionCard.confirmed")}
                  </span>
                )}

                {session.status === "almostFull" && (
                  <span
                    className={classnames([
                      styles.session_status,
                      styles.status_almostFull,
                    ])}
                  >
                    <i className={"icon-fire"} />
                    {t("ui.components.base.sessionCard.almostFull")}
                  </span>
                )}
                {session.status === "waitingList" && (
                  <span
                    className={classnames([
                      styles.session_status,
                      styles.status_waitingList,
                    ])}
                  >
                    <i className={"icon-bell-alt"} />
                    {t("ui.components.base.sessionCard.waitingList")}
                  </span>
                )}

                {session.status === "soldOut" && (
                  <span
                    className={classnames([
                      styles.session_status,
                      styles.status_soldOut,
                    ])}
                  >
                    <i className={"icon-paper-plane"} />
                    {t("ui.components.base.sessionCard.soldOut")}
                  </span>
                )}
              </div>
              <div className={classnames([styles.card_price])}>
                <div>
                  <span>{getTourCurrency(session.currency)}</span>
                  {session.price}
                </div>
              </div>
            </div>

            {directBooking === false ? (
              <Link to={tourLink} target="_self">
                <Button
                  className={classnames([styles.cta_book])}
                  variant={"link"}
                >
                  {t("ui.components.base.sessionCard.showMore")}
                </Button>
              </Link>
            ) : (
              <Button
                className={classnames([styles.cta_book])}
                variant={"link"}
                onClick={openCustomerly}
              >
                <span className={classnames([styles.cta])}>
                  {t("ui.components.base.sessionCard.bookNow")}
                </span>
              </Button>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default SessionCard
